.container-loading {
  position: relative;
  width: 100%;
  height: 100vh;
}

.wrapper-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.wrapper-loader p {
  font-weight: medium;
  color: #00aff1;
  margin-bottom: 8px;
}

#subscriptions-history {
  width: 100%;
  height: 100vh;
  border: none;
}

.bg-subscriptions {
  background-color: white;
}

.state-changed {
  position: relative;
  background: #b3d8e6;
  width: 200px;
  height: 8px;
  border-radius: 100px;
}

.state-changed::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 100px;
  background: #00aff1;
  animation: progress 1.5s infinite ease-in-out;
}

.state-changed::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 100px;
  background: #00aff1;
  animation: progress 1.5s infinite ease-in-out;
}

@keyframes progress {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    right: 0;
    width: 0;
  }
}
