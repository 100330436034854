.custom-field-event-group .MuiInputBase-root {
    height: 100%;
    display: flex;
    align-items: flex-start;
    min-height: 108px;
}

.button-add .css-sghohy-MuiButtonBase-root-MuiButton-root{
    background-color: #00AFF0;
    color: #FFF;
}
.container-group-event {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap:20px;
    align-items: center;
}

.field-group-event{
    width: 80%;
}

.button-add {
    width: 20%;
}