@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf");
}

::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
}

* {
  font-family: "Poppins";
}

html {
  scroll-behavior: smooth;
}

#menu-auth.MuiPaper-root {
  top: 0 !important;
}

/* CUSTOM SCROLLBAR  */

.MuiDialog-paper::-webkit-scrollbar {
  width: 0.7em;
}

.MuiDialog-paper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.MuiDialog-paper::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.MuiButton-containedPrimary {
  background-color: #00aff0;
  color: #fff;
}

/* SWAL */
.swal2-popup {
  font-size: 0.7rem !important;
}

.swal2-container {
  z-index: 1300;
}

/* PRE */
pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

/* INPUT Remove spinner on input numbers */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* REACT CROPPER */

/* override react cropper showing image with max-height, so image will not be higher as screen */
.ReactCrop__image {
  /* max-height: 250px; */
  max-height: 64vh;
}

/* QR PAGE OVERRIDE */
#section-qr {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
